/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:c038f744-9e4e-4755-959a-e481044752e7",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_0oLGfxUpR",
    "aws_user_pools_web_client_id": "1kku8brilvvdlhp7nqh0rk1c0c",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://xmqbtpubofdspfxipfhgbzttjm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "hitoterracerestapi",
            "endpoint": "https://fwm9fid7ua.execute-api.ap-northeast-1.amazonaws.com/demo",
            "region": "ap-northeast-1"
        }
    ],
    "aws_content_delivery_bucket": "hitoterrace-web-demo",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1580l8702cyjk.cloudfront.net",
    "aws_mobile_analytics_app_id": "f6a25e6d91db42d8b97d034bd7254538",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_user_files_s3_bucket": "hitoterrace152138-demo",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
